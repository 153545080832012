nav.nav {
    @media (min-width: theme('screens.lg')) {
        position: relative;
        display: block;
        border-radius: 0;
        width: 100%;
        height: auto;
        border-bottom: theme('topNavigation.border');
        transform: none;
        box-shadow: none;
        overflow: visible;
        z-index: 3;

        & > [data-dd-header=true] {
            display: none;
        }

        & [data-dd-menu=true] {
            max-width: theme('container.sizes.large');
            width: 100%;
            margin: 0 auto;

            & > div > ul {
                display: flex;
                height: auto;
                overflow: visible;

                & > li {
                    margin-top: 0 !important;
                    padding: 0;
                    border-top: none;

                    &:hover,
                    &:focus-within {
                        & > nav {
                            opacity: 1;
                            pointer-events: all;
                            transform: translateY(0);
                        }
                    }

                    & > button {
                        display: none;
                    }

                    & > a,
                    & > span {
                        @apply type-paragraph type-090;

                        display: flex;
                        padding: theme('spacing.100') theme('spacing.150');
                    }
                }
            }
        }

        & nav[data-dd-submenu=true] {
            top: calc(100% + 1px);
            left: auto;
            width: auto;
            height: auto;
            max-height: calc(100vh - 10rem);
            box-shadow: theme('card.default.shadow');
            border: theme('card.default.border');
            border-radius: theme('card.borderRadius.large');
            background-color: theme('card.default.background');
            white-space: normal;
            transform: translateY(-1rem);
            transition: transform theme('transitionDuration.medium') theme('transitionTimingFunction.default'),
                opacity theme('transitionDuration.medium') theme('transitionTimingFunction.default');
            opacity: 0;
            z-index: 600;
            pointer-events: none;
            overflow: auto;

            /**
            * There is a 1px border shown on the bottom of the main nav. When the mega-menu is shown on hover,
            * it is offset by 1px so that it is rendered beneath the border, otherwise, the border would be
            * covered. This causes a bug where if your mouse is in the right spot, the menu will open, you
            * move your mouse slightly, and the menu will instantly close. Adding this before prevents this
            * as it helps preserve the focus-within state when the mouse is moving.
            */
            &::before {
                content: '';
                position: absolute;
                top: -1px;
                left: 0;
                height: 1px;
                width: 100%;
            }
        }

        & [data-dd-dropdown=true] {
            padding: theme('spacing.100');

            & a:hover {
                border-radius: theme('borderRadius.100');
                background-color: theme('dropdownItem.hover.background');
                color: theme('dropdownItem.hover.textColor');
            }
        }

        & [data-dd-submenu-title],
        & [data-dd-submenu-callout] {
            display: none;
        }
    }
}
