.subMenu {
    @media (min-width: theme('screens.lg')) {
        left: 0;
        width: 100% !important;

        & > div {
            padding: theme('spacing.200');
            overflow: auto !important;
        }
    }
}

.header {
    display: none;

    @media (min-width: theme('screens.lg')) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.list {
    @media (min-width: theme('screens.lg')) {
        @apply grid-cols-4;

        display: grid;
        gap: theme('spacing.200');
        margin-top: theme('spacing.200');
    }
}
