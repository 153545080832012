.panel {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: theme('topNavigation.background');
    transform: translateX(100%);
    transition: theme('transitionDuration.fast') transform theme('transitionTimingFunction.default');
    overflow: auto;
}

.panelOpen {
    transform: translateX(0);
}

.topLevelPanel {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;

    @media (min-width: theme('screens.lg')) {
        overflow: visible;
    }

    & > div > ul > li {
        padding: theme('spacing.050');
        border-top: 1px solid theme('colors.decorators.subtle');

        &[data-demoted] + li[data-demoted] {
            @apply -mt-050;

            border-top: none;
        }

        &:first-child {
            border-top: none;
        }

        & > a,
        & > button {
            padding: theme('spacing.075') theme('spacing.100');
        }
    }
}

.title {
    padding: theme('spacing.100');
    border-bottom: 1px solid theme('colors.decorators.subtle');
    flex-shrink: 0;
    flex-grow: 0;
}

.callout {
    width: 100%;
    padding: theme('spacing.075');
    border-top: 1px solid theme('colors.decorators.subtle');
    background-color: theme('sidebarNavigation.background');
    box-shadow: 0px 0px 12px 0px rgba(48, 60, 105, 0.25);
    text-align: center;
    flex-shrink: 0;
    flex-grow: 0;

    a {
        min-width: 9.75rem;
    }
}
