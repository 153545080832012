.nav {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 19.625rem;
    height: 100dvh;
    border-radius: theme('card.borderRadius.large') 0 0 theme('card.borderRadius.large');
    background-color: theme('topNavigation.background');
    color: theme('topNavigationLink.default.textColor');
    transform: translateX(100%);
    box-shadow: theme('boxShadow.default');
    transition: theme('transitionDuration.fast') transform theme('transitionTimingFunction.default');
    z-index: theme('zIndex.flyout');
    overflow: hidden;

    &.navOpen {
        transform: translateX(0);
    }
}
