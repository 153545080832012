.listItem {
    & > a,
    & > button,
    & > span {
        @apply type-emphasis type-100;

        display: flex;
        gap: theme('topNavigationLink.spacing');
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    & > button {
        & + a,
        & + span {
            display: none;
        }
    }
}

.listItemPaddingSmall {
    & > a,
    & > button,
    & > span {
        padding: theme('spacing.075') theme('spacing.100');
    }
}

.listItemPaddingLarge {
    & > a,
    & > button,
    & > span {
        padding: theme('spacing.100') theme('spacing.100');
    }
}
