.subMenu {
    @media (min-width: theme('screens.lg')) {
        & > div {
            @apply grid-cols-2;

            display: grid;
            padding: 0;
        }
    }
}

.list {
    @media (min-width: theme('screens.lg')) {
        @apply grid-cols-2;

        display: grid;
        gap: theme('spacing.100') theme('spacing.150');
        margin-top: theme('spacing.200');
    }
}

.callout {
    display: none;

    @media (min-width: theme('screens.lg')) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: theme('spacing.200');
        border-left: 1px solid theme('colors.decorators.subtle');
    }
}
