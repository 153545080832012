.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: theme('spacing.150') theme('spacing.100');
    border-bottom: 1px solid theme('colors.decorators.subtle');
}

.backButton {
    position: relative;
    display: flex;
    align-items: center;
    gap: theme('spacing.050');
    flex-grow: 1;
    padding: theme('spacing.025') theme('spacing.050');

    img {
        margin-top: -0.125rem;
        opacity: 0;
        transition: theme('transitionDuration.medium') opacity theme('transitionTimingFunction.default');
    }

    & > span {
        display: grid;
        transform: translateX(-1.5rem);
        transition: theme('transitionDuration.medium') transform theme('transitionTimingFunction.default');

        & span {
            grid-area: 1 / 1;
            transition: theme('transitionDuration.medium') opacity theme('transitionTimingFunction.default');
        }

        & span:first-child {
            opacity: 0;
        }

        & span:last-child {
            opacity: 1;
        }
    }

    &.backButtonActive {
        img {
            opacity: 1;
        }

        & > span {
            transform: translateX(0);

            & span:first-child {
                opacity: 1;
            }

            & span:last-child {
                opacity: 0;
            }
        }
    }
}
